.education-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: auto;
  border: 3px solid var(--fadedBlack);
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.education-box-sheet {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: var(--darkFadedBlack);

  transform: scaleX(0);
  transform-origin: left;
  transition: all 400ms;
}

.education-box-sheet.hover {
  transform: scaleX(1);
}

.education-box-image-box {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: all 400ms;
  z-index: -1;
}

.education-box-image-box.hover {
  transform: scale(1.2);
}

.education-box-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.education-box-title {
  left: 0;
  top: 0;
  position: absolute;

  background-color: white;
  color: black;

  font-size: 30px;
  font-weight: 700;
  white-space: nowrap;

  width: 0;
  transform-origin: left;
  transition: all 400ms ease 100ms;

  mix-blend-mode: screen;
}

.education-box-title.hover {
  padding: 3% 4%;
  width: 100%;
}

.education-box-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 86%;
  margin: 36% 7% 30% 7%;
  font-size: 18px;
  z-index: 2;

  opacity: 0;
  transform: translateY(2%);
  filter: blur(3px);
  transition: all 500ms ease 200ms;
}

.education-box-icon {
  position: absolute;
  bottom: 3%;

  opacity: 0;
  transform: translateY(2%);
  filter: blur(3px);
  transition: all 500ms ease;
}

.education-box-info.hover,
.education-box-icon.hover {
  opacity: 1;
  transform: translateY(0);
  filter: blur(0);
}

@media only screen and (max-width: 1170px) {
  .education-box {
    height: 100%;
  }

  .education-box-info {
    font-size: 16px;
  }
}
