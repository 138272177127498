.education-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 90px);
  margin-top: 60px;
  margin-bottom: 80px;
}

.education-title {
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 30px;
}

.education-area {
  display: flex;
  justify-content: center;
  width: 80%;
  gap: 10px;
}

@media only screen and (max-width: 1170px) {
  .education-container {
    min-height: calc(100vh - 80px);
    margin-bottom: 50px;
  }

  .education-title {
    font-size: 40px;
    text-align: center;
  }

  .education-area {
    width: 100%;
    overflow: visible;
  }

  .education-swiper-slide {
    width: 80% !important;
    height: auto !important;
  }
}
