.aboutus-container {
  min-height: calc(100vh - 90px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12% 3% 12%;
  gap: 12%;
  background-color: var(--primaryColor);
}

.aboutus-zone {
  display: flex;
  flex: 1;
}

.aboutus-zone.left {
  transform: scale(0.8);
}

.aboutus-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.aboutus-zone.right {
  flex-direction: column;
  gap: 24px;
}

.aboutus-header {
  font-size: 50px;
  font-weight: 700;
}

.aboutus-text {
  font-size: 18px;
}

.aboutus-highlight {
  font-weight: 700;
  color: var(--secondaryColor);
}

@media only screen and (max-width: 1170px) {
  .aboutus-container {
    min-height: calc(100vh - 80px);
    padding: 0 7% 3% 7%;
  }

  .aboutus-zone.left {
    display: none;
  }
}
