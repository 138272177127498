.bg {
  position: fixed;
  z-index: -10;
  width: 100%;
  height: 100%;

  background-image: linear-gradient(
    to bottom left,
    var(--backgroundStart),
    var(--backgroundEnd)
  );

  background-size: cover;
  background-position: center center;
}
