.schedule-container {
  display: flex;
  flex-direction: column;
  padding: 124px 1vw 40px 1vw;
  min-width: 100vw;
}

.schedule-header-box {
  margin-left: 8%;
  margin-bottom: 36px;
  padding: 8px 20px;
  border-bottom: 1px solid white;
  align-self: flex-start;
}

.schedule-header {
  font-size: 40px;
}

.schedule-info {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.8);
}

.schedule-extra-info {
  font-size: 16px;
  margin-top: 6px;
  font-weight: bold;
  font-style: italic;
}

.schedule-zone {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  padding-left: 6%;
  padding-right: 2%;
  gap: 36px;
  overflow: hidden;
  width: 100%;
}

.schedule-seperator {
  display: none;
}

.schedule-strip {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.schedule-strip-title {
  font-size: 36px;
  white-space: nowrap;
  margin-left: 4%;
}

.schedule-strip-list {
  display: flex;
  gap: 10px;
  padding: 0 2%;
  overflow-x: scroll;
  cursor: pointer;
  user-select: none;
}

.schedule-strip-list::-webkit-scrollbar {
  display: none;
}

.schedule-box {
  display: flex;
  flex-direction: column;
  min-width: 260px;
  width: 18%;
  gap: 8px;
}

.schedule-box-info-box {
  display: flex;
  flex: 1;
  justify-content: space-between;
  width: 100%;
  aspect-ratio: 1.8;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.54);
  border-radius: 10px;
  padding: 12px 16px 8px 16px;
  gap: 8px;
}

.schedule-box-title-box {
  display: flex;
  height: 100%;
  align-items: center;
}

.schedule-box-title {
  font-size: 18px;
  margin-left: 8px;
}

.schedule-box-speaker-box {
  display: flex;
  align-items: center;
  gap: 8px;
}

.schedule-box-speaker {
  font-size: 14px;
  flex: 5;
}

.schedule-box-image-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  aspect-ratio: 1;
  border-radius: 100%;
  overflow: hidden;
}

.schedule-box-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.schedule-box-time {
  margin-left: 16px;
}

@media only screen and (max-width: 1170px) {
  .schedule-container {
    padding: 0;
    padding-top: 100px;
    padding-bottom: 80px;
  }

  .schedule-header-box {
    margin: 0 4%;
    margin-bottom: 28px;
    padding: 8px 12px;
  }

  .schedule-header {
    font-size: 28px;
    margin-bottom: 10px;
  }

  .schedule-info {
    font-size: 18px;
  }

  .schedule-extra-info {
    font-size: 14px;
    margin-top: 8px;
  }

  .schedule-zone {
    padding-left: 0;
    padding-right: 0;
    gap: 20px;
  }

  .schedule-seperator {
    display: block;
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.8);
  }
  .schedule-strip-list {
    gap: 16px;
    padding: 0 2%;
    overflow-x: scroll;
  }

  .schedule-box-info-box {
    aspect-ratio: 3.6;
  }
}
