.loading-frame {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background-color: var(--primaryColor);
    z-index: 99;
    margin-bottom: -100vh;
}

.loading-frame.faint {
    animation: faint 1500ms linear forwards;
}

@media only screen and (max-width: 1170px) {
    .transition-image {
        width: 250%;
    }
}

@keyframes faint {
    0% {
    }
    100% {
        opacity: 0;
        display: none;
        z-index: -99;
    }
}

.loader {
    box-sizing: border-box;
    position: relative;
    width: 48px;
    height: 48px;
    animation: spin 1s linear infinite;
}
.loader:after,
.loader:before {
    content: "";
    width: 24px;
    height: 24px;
    position: absolute;
    border-radius: 50%;
    background: var(--secondaryColor);
    animation: spin 1s linear infinite;
    transform-origin: 0px 100%;
}
.loader:before {
    transform-origin: 0 50%;
    background: var(--tertiaryColor);
}
@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
