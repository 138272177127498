/* ======= Custom Font ======= */
@font-face {
    font-family: Centra;
    src: url("./assets/font/CentraNo2-Bold.ttf");
    font-weight: 700;
}
@font-face {
    font-family: Centra;
    src: url("./assets/font/CentraNo2-Medium.ttf");
    font-weight: 500;
}
@font-face {
    font-family: Centra;
    src: url("./assets/font/CentraNo2-Book.ttf");
    font-weight: 400;
}

/* ======= App colors ======= */
:root {
    --primaryColor: #233b77;
    --secondaryColor: #f39314;
    --tertiaryColor: #c1203b;

    --fontColor: #ffffff;
    --darkFadedBlack: #000000bb;
    --fadedBlack: #00000033;
    --primaryColorHalf: #233b7799;

    --backgroundStart: #0c1f4e;
    --backgroundEnd: #11224df3;
}

/* ======= Default css ======= */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
    scroll-padding-top: 75px;
}

body {
    font-weight: 400;
    overflow-x: hidden;
    position: relative;
    height: 100vh;
    color: var(--fontColor);
    font-family: "Centra", sans-serif;
}

a {
    color: var(--fontColor);
    text-decoration: none;
    font-family: "Centra", sans-serif;
}

button {
    font-family: "Centra", sans-serif;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.transition-image-box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    z-index: -5;
    overflow: hidden;
}

.transition-image-box.rotate {
    transform: rotate(180deg) translateY(100%);
}

.transition-image {
    width: 100%;
}
